<template>
  <div class="chat-block">
    <div class="default-chat-screen" v-if="firstClick">
      <div>
        <ion-icon name="chatbubbles-outline" class="screen-icon"></ion-icon>
      </div>
      <h1>
        Click on your contacts to see chats. Or search your contact first.
      </h1>
    </div>
    <div class="profile" v-if="!firstClick">
      <div class="profile-image">
        <img
          v-if="chatterProfilePic"
          class="round-image"
          :src="chatterProfilePic"
        />
        <img v-else class="round-image" src="@/images/default-avatar.png" />
      </div>
      <div class="profile-info" v-if="chatterProfile != null">
        <h3 class="profile-name">
          {{ chatterProfile.firstName + " " + chatterProfile.lastName }}
        </h3>
        <h3 class="profile-major">{{ chatterProfile.major }}</h3>
        <h3 class="profile-career" v-if="currentJob != null">
          {{ chatterProfile.firstName }}
          <p v-if="currentJob.currentInRole">works</p>
          <p v-else>worked</p>
          at {{ currentJob.company }}.
        </h3>
      </div>
    </div>
    <div class="chat-box" v-show="!firstClick">
      <div class="chat-field" ref="chatField">
        <ul class="chat-history">
          <MsgBubble
            v-for="msg in chatData"
            :key="msg.id"
            :msg="msg"
            :senderPosition="senderPosition"
            :chatterProfilePic="chatterProfilePic"
            :selfProfilePic="selfProfilePic"
            @chatChanged="handleChange"
          />
        </ul>
      </div>
      <div class="type-field invisible">
        <div class="input-field">
          <textarea
            ref="typeField"
            autofocus
            placeholder="Type your message here ..."
            name="message-input-field"
            id="type-box"
            wrap="hard"
            v-model="typeContent"
            @keyup.enter.exact="handleSend"
            @keydown.enter.shift.exact="newline"
          ></textarea>
        </div>
        <div class="button-bar">
          <input
            type="submit"
            name="send-button"
            class="send-btn"
            id="the-btn"
            :value="btnTxt"
            :class="{
              'disabled': isSending,
            }"
            @click="handleSend"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import getChat from "@/composables/Msg/getChat";
import sendChat from "@/composables/Msg/sendChat";
import getProfile from "@/composables/Profile/getProfile";
import getUrlAndName from "@/composables/Storage/getUrlAndName";
import MsgBubble from "./msgBubble.vue";
import { ref, watchEffect, watch, onMounted } from "vue";

import firebase from "firebase/app";
import "firebase/firestore";

export default {
  props: ["firstClick", "chatData", "senderPosition", "chatId", "chatterId"],
  components: {
    MsgBubble,
  },
  async setup(props) {
    const { user } = getUser();
    const chatterProfile = ref(null);
    const chatterProfilePic = ref(null);
    const selfProfilePic = ref(null);
    const currentJob = ref(null);
    const typeContent = ref("");
    const chatField = ref(null);
    const isSending = ref(false);
    const btnTxt = ref("Send")
    const typeField = ref(null)

    await getUrlAndName(selfProfilePic, null, user.value.uid, "Images");
    // console.log(chatterProfilePic.value);

    watchEffect(async () => {
      if (props.chatterId) {
        await getProfile(chatterProfile, props.chatterId);
        await getUrlAndName(chatterProfilePic, null, props.chatterId, "Images");
        if (chatterProfile.value.experience) {
          if (chatterProfile.value.experience.length > 0) {
            chatterProfile.value.experience.forEach((exp) => {
              if (exp.currentInRole) {
                currentJob.value = {
                  company: exp.company,
                  currentInRole: exp.currentInRole,
                };
                return;
              }
            });
            if (currentJob.value == null) {
              currentJob.value = {
                company: chatterProfile.value.experience[0].company,
                currentInRole: chatterProfile.value.experience[0].currentInRole,
              };
            }
          }
        }
      }
    });

    const handleSend = async () => {
      if (!isSending.value){
        if (typeContent.value.trim()) {
          const now = new firebase.firestore.Timestamp.now().toDate();
          let tempMessage = typeContent.value
          typeContent.value = ""
          isSending.value = true
          btnTxt.value = "Sending"
          await sendChat(
            props.chatId,
            tempMessage,
            props.senderPosition,
            now
          );
          isSending.value = false
          btnTxt.value = "Send"

          chatField.value.scroll({
            top: chatField.value.scrollHeight,
            behavior: "smooth",
          });
        }
      }
      typeField.value.focus()
    };

    const handleChange = () => {
      chatField.value.scroll({
        top: chatField.value.scrollHeight,
        behavior: "smooth",
      });
    };

    return {
      typeField,
      btnTxt,
      isSending,
      chatterProfile,
      chatterProfilePic,
      selfProfilePic,
      typeContent,
      chatField,
      handleSend,
      handleChange,
      currentJob,
    };
  },
};
</script>

<style scoped>
.chat-block {
  height: 100%;
  border-left: 1px solid #c4c4c4;
  background-color: #f1f1f1;
  flex: 0 0 70%;
}

.default-chat-screen {
  width: 100%;
  color: #808080;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.default-chat-screen div {
  margin-top: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}

.screen-icon {
  font-size: 500%;
}

.default-chat-screen h1 {
  text-align: center;
  width: 50vw;
  word-wrap: break-word;
}

.profile {
  height: 14vh;
  align-items: center;
  padding: 1vw;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
}

.profile-image {
  flex: 0 1 10%;
  padding-left: 0.75vw;
}

.profile-info {
  flex: 1 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  line-height: 30px;
}

.profile-major {
  font-weight: normal;
}

.profile-career {
  font-weight: normal;
  color: #808080;
  width: 40vw;
  overflow: hidden;
}

.profile-career p {
  display: inline;
}

.chat-history {
  list-style: none;
}

.chat-field {
  padding: 0 2vw;
  height: 64vh;
  overflow-y: scroll;
}

.chat-history {
  width: 100%;
  height: 95%;
}

.type-field {
  padding: 0 4vh;
  background-color: white;
  border-top: 1px solid #c4c4c4;
  height: 22vh;
  width: 100%;
}

.input-field {
  height: 50%;
  width: 100%;
}

.input-field textarea,
.input-field textarea:focus {
  background-color: white;
  border: none;
  font-size: 100%;
  padding: 1vh;
  margin-top: 3vh;
  height: 100%;
  width: 100%;
  resize: none;
}

.button-bar {
  margin-top: 3vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30%;
  padding-right: 5%;
  padding-bottom: 1%;
}

.button-bar input {
  height: 70%;
  border: none;
  color: white;
  border-radius: 2vh;
  background-color: var(--purple-l);
  min-width: 150px;
}

.button-bar input:hover {
  background-color: var(--purple);
  cursor: pointer;
}

.disabled {
  background-color: var(--purple) !important;
  cursor: auto !important;
}

@media screen and (max-width: 768px) {
  .profile-image {
    display: none;
  }

  .type-field {
    padding: 0 1vh;
  }
}
</style>
